import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

// import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __deleteApiData, __getApiData, __HOST, __postApiData } from "constants/apis"
import UploadFile from "./comp/UploadFile"
import EditDetails from "./comp/EditDetails"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import DeleteModal from "components/Common/DeleteModal"
import axios from "axios"

const UploadIntrested = props => {
  document.title = "Contact |  Lead"

  const [state, setState] = useState({
    modal1: false,
    leads: [],
    filesData: [],
    isLoading: false,
    isDelete: false,
    delete_id: null,
    isEdit: false,
    edit: null,
    isAsyncLoading: false,
  })
  const {
    modal1,
    leads,
    filesData,
    isLoading,
    isDelete,
    delete_id,
    isEdit,
    edit,
    isAsyncLoading,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))


  const columns = useMemo(
    () => [
      {
        Header: "Uploaded By",
        accessor: "userId.name",
        disableFilters: true,
      },
      {
        Header: "Uploaded By",
        accessor: "userId.email",
        disableFilters: true,
      },
      {
        Header: "File Name",
        accessor: "file_name",
        disableFilters: true,
      },
      {
        Header: "File Type",
        accessor: "originalname",
        disableFilters: true,
      },
      {
        Header: "Size",
        accessor: "size",
        disableFilters: true,
        Cell: ({ value }) => bytesToMB(value),
      },
      {
        Header: "Action",
        accessor: "_id",
        filterable: false,
        disableFilters: true,
        Cell: ({ value, row }) => (
          <>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => __handleDownloadFile(row.original._id)}
            >
              <i className="bx bx-download"></i>
              {/* {row.original._id} */}
            </button>
            <button
              className="btn btn-danger btn-sm ms-2"
              onClick={() => {
                updateState({
                  isDelete: true,
                  delete_id: value,
                });
              }}
            >
              <i className="bx bx-trash"></i>
            </button>
          </>
        ),
      },
    ],
    [] // dependencies array
  );
  // Function to convert bytes to MB
  function bytesToMB(bytes) {
    if (bytes < 1024 * 1024) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else {
      return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    }
  }
  // Get the size in MB
  // const sizeInMB = bytesToMB(2000);


  const __handleDownloadFile = (fileId) => {
    updateState({ isLoading: true });

    axios({
      url: `${__HOST()}/api/v1/admin/download/${fileId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('authUser'),
      },
      responseType: 'blob', // Set responseType to 'blob' to handle binary data
    })
      .then((res) => {
        // Extract the file name and mime type from the response headers
        const contentDisposition = res.headers['content-disposition'];
        const mimeType = res.headers['content-type'];

        let fileName = 'downloaded_file';
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?(.+)"?/);
          if (match) {
            fileName = match[1];
          }
        }

        const url = window.URL.createObjectURL(new Blob([res.data], { type: mimeType }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the DOM after download
        updateState({ isLoading: false });
      })
      .catch((error) => {
        console.error('Download error:', error);
        updateState({ isLoading: false });
      });
  };


  const __handleGetFiles = () => {
    __getApiData(`/api/v1/admin/admin/files`)
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({ filesData: res.data.files })
          return updateState({
            filesData: res.data.files,
            isLoading: false,
            isAsyncLoading: false,
          })
        }
        return updateState({
          filesData: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          filesData: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ leads: [], isLoading: true, filesData: [] })
    // __handleGetUsers("")
    __handleGetFiles()
  }, [])


  const __handleDeleteFile = () => {
    axios.delete(`${__HOST()}/api/v1/admin/files/${delete_id}`, {
      headers: {
        'auth-token': localStorage.getItem('authUser'),
      },
    })
      .then(res => {
        console.log(res, "res")
        if (res.data.message === "File deleted successfully") {
          __handleGetFiles();
          updateState({ isDelete: false, isAsyncLoading: false });
        } else {
          updateState({ isLoading: false, isAsyncLoading: false });
        }
      })
      .catch(error => {
        console.error(error);
        updateState({ isLoading: false, isAsyncLoading: false });
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title={props.t("Contact")} breadcrumbItems={[]} /> */}
          <DeleteModal
            show={isDelete}
            onDeleteClick={__handleDeleteFile}
            onCloseClick={() =>
              updateState({ isDelete: false, delete_id: null })
            }
          />
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={filesData}
                customPageSize={30}
                isAsyncGlobalFilter
                isNextPrvButtonsEnabled={filesData?.length != 0}
                isAddUserList={"Upload Files"}
                handleUserClick={() => {
                  updateState({ modal1: true })
                }}
                handleAsyncSearch={value => {
                  // __handleGetUsers(value)
                  __handleGetFiles(value)
                  updateState({ isAsyncLoading: true })
                }}
                isAsyncLoading={isAsyncLoading}
                classNameHeader="dark"
              />
              {filesData?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No Files Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
          {editModel()}
        </Container>
      </div>
      {modelBox1()}
    </React.Fragment>
  )
  function modelBox1() {
    return (
      <Modal isOpen={modal1} centered size="xl">
        <ModalHeader
          toggle={() => {
            updateState({ modal1: false })
          }}
        >
          Upload File
        </ModalHeader>
        <ModalBody className="rounded-0 p-5">
          <UploadFile />
        </ModalBody>
      </Modal>
    )
  }
  function editModel(params) {
    return (
      <>
        <EditDetails
          isEdit={isEdit}
          edit={edit}
          closeButton={() => updateState({ isEdit: false })}
          completeFun={() => {
            updateState({ isEdit: false })
            // __handleGetUsers()
          }}
        />
      </>
    )
  }
}

export default withTranslation()(UploadIntrested)
