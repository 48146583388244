import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";
import { withTranslation } from "react-i18next";
import { __getApiData, __postApiData } from "constants/apis";
import { __formatDate, __formatDate2 } from "constants/constantfun";
// import { generate } from "@pdfme/generator";
// import { tableBeta } from "@pdfme/schemas"; 
// import html2canvas from "html2canvas"
import 'jspdf-autotable';
import jsPDF from "jspdf"
import * as XLSX from "xlsx";


const UserCallReport = props => {
  document.title = "User Call Report | Leads";

  const [state, setState] = useState({
    list: [],
    isLoading: false,
    showFilter: false,
    campaign_type: [],
    from_date: __formatDate2(new Date()),
    to_date: __formatDate2(new Date()),
  });

  const { list, isLoading, showFilter, from_date, to_date } = state;

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>;
        },
      },
      {
        Header: <div className="text-center">Role</div>,
        accessor: "role",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Phone</div>,
        accessor: "phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Date</div>,
        accessor: "date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Total Calls Attempted</div>,
        disableFilters: true,
        accessor: "total_call_attempts",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Total Calls Connected</div>,
        disableFilters: true,
        accessor: "total_call_connected",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Total Calls Not Connected</div>,
        disableFilters: true,
        accessor: "total_call_not_connected",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Total Calls In Progress</div>,
        disableFilters: true,
        accessor: "total_call_in_progress",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Leads Marked As Converted</div>,
        disableFilters: true,
        accessor: "total_call_converted",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Leads Marked As Lost</div>,
        disableFilters: true,
        accessor: "total_call_lost",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Total Call Time</div>,
        disableFilters: true,
        accessor: "total_time_taken",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
      {
        Header: <div className="text-center">Average Call Time</div>,
        disableFilters: true,
        accessor: "average_time_taken",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>;
        },
      },
    ],
    []
  );

  const updateState = data => setState(state => ({ ...state, ...data }));

  const __handleGetUsers = () => {
    updateState({ list: [], isLoading: true });

    __postApiData(`/api/v1/admin/g_user_call_report`, {
      from_date: new Date(from_date).valueOf(),
      to_date: new Date(to_date).valueOf(),
    })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            list: res.data.map((item, i) => ({ ...item, sr_no: i + 1 })).reverse(),
            isLoading: false,
          });
        }
        return updateState({ list: [], isLoading: false });
      })
      .catch(error => {
        console.error(error);
        return updateState({ list: [], isLoading: false });
      });
  };

  useEffect(() => {
    __handleGetUsers();
  }, []);


  // const generatePdf4 = async () => {
  //   const data = state.list; // Assuming state.list contains your table data
  //   console.log(data, "data")
  //   const template = {
  //     schemas: [
  //       {
  //         mytable: {
  //           type: "table",
  //           position: { x: 28.706846058368317, y: 37.85750960310253 },
  //           width: 150,
  //           height: 57.5184,
  //           content: data.map(item => [item.name, item.city, item.description].join(',')), // Joining array into a string
  //           showHead: true,
  //           head: ["Name", "City", "Description"],
  //           headWidthPercentages: [30, 30, 40],
  //           tableStyles: { borderWidth: 0.3, borderColor: "#000000" },
  //           headStyles: {
  //             fontName: "NotoSerifJP-Regular",
  //             fontSize: 13,
  //             characterSpacing: 0,
  //             alignment: "left",
  //             verticalAlignment: "middle",
  //             lineHeight: 1,
  //             fontColor: "#ffffff",
  //             borderColor: "",
  //             backgroundColor: "#2980ba",
  //             borderWidth: { top: 0, right: 0, bottom: 0, left: 0 },
  //             padding: { top: 5, right: 5, bottom: 5, left: 5 },
  //           },
  //           bodyStyles: {
  //             fontName: "NotoSerifJP-Regular",
  //             fontSize: 13,
  //             characterSpacing: 0,
  //             alignment: "left",
  //             verticalAlignment: "middle",
  //             lineHeight: 1,
  //             fontColor: "#000000",
  //             borderColor: "#888888",
  //             backgroundColor: "",
  //             alternateBackgroundColor: "#f5f5f5",
  //             borderWidth: { top: 0.1, right: 0.1, bottom: 0.1, left: 0.1 },
  //             padding: { top: 5, right: 5, bottom: 5, left: 5 },
  //           },
  //           columnStyles: {},
  //         },
  //       },
  //     ],
  //     basePdf: { width: 210, height: 297, padding: [10, 10, 10, 10] },
  //   };

  //   const inputs = [
  //     {
  //       mytable: data.map(item => ({
  //         Name: item.name,
  //         City: item.city,
  //         Description: item.description,
  //       })),
  //     },
  //   ];

  //   try {
  //     const pdf = await generate({ template, inputs, plugins: { Table: tableBeta } });
  //     const blob = new Blob([pdf.buffer], { type: "application/pdf" });
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "UserCallReport.pdf");
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(url); // Clean up
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };

  // const generatePdf5 = async () => {
  //   const data = [
  //     {
  //       _id: '668ba77b3d9a82a3754202a3',
  //       role: 'EXECUTIVE',
  //       name: 'vaishali',
  //       phone: 9251054871,
  //       total_call_attempts: 0,
  //       total_call_connected: 0,
  //       total_call_not_connected: 0,
  //       total_call_in_progress: 0,
  //       total_call_converted: 0,
  //       total_call_lost: 0,
  //       total_time_taken: 0,
  //       average_time_taken: 0,
  //       date: '7/10/2024',
  //       sr_no: 11
  //     },
  //     {
  //       _id: '668655b33d9a82a37541ffa7',
  //       role: 'EXECUTIVE',
  //       name: 'khushi',
  //       phone: 9251054874,
  //       total_call_attempts: 0,
  //       total_call_connected: 0,
  //       total_call_not_connected: 0,
  //       total_call_in_progress: 0,
  //       total_call_converted: 0,
  //       total_call_lost: 0,
  //       total_time_taken: 0,
  //       average_time_taken: 0,
  //       date: '7/10/2024',
  //       sr_no: 10
  //     },
  //     // Add more entries as needed
  //   ];

  //   const template = {
  //     schemas: [
  //       {
  //         mytable: {
  //           type: 'table',
  //           position: { x: 28.706846058368317, y: 37.85750960310253 },
  //           width: 150,
  //           height: 57.5184,
  //           content: '', // Will be populated dynamically below
  //           showHead: true,
  //           head: ['Name', 'Phone', 'Role', 'Total Calls'],
  //           headWidthPercentages: [30, 25, 25, 20],
  //           tableStyles: { borderWidth: 0.3, borderColor: '#000000' },
  //           headStyles: {
  //             fontName: 'NotoSerifJP-Regular',
  //             fontSize: 13,
  //             characterSpacing: 0,
  //             alignment: 'left',
  //             verticalAlignment: 'middle',
  //             lineHeight: 1,
  //             fontColor: '#ffffff',
  //             borderColor: '',
  //             backgroundColor: '#2980ba',
  //             borderWidth: { top: 0, right: 0, bottom: 0, left: 0 },
  //             padding: { top: 5, right: 5, bottom: 5, left: 5 },
  //           },
  //           bodyStyles: {
  //             fontName: 'NotoSerifJP-Regular',
  //             fontSize: 13,
  //             characterSpacing: 0,
  //             alignment: 'left',
  //             verticalAlignment: 'middle',
  //             lineHeight: 1,
  //             fontColor: '#000000',
  //             borderColor: '#888888',
  //             backgroundColor: '',
  //             alternateBackgroundColor: '#f5f5f5',
  //             borderWidth: { top: 0.1, right: 0.1, bottom: 0.1, left: 0.1 },
  //             padding: { top: 5, right: 5, bottom: 5, left: 5 },
  //           },
  //           columnStyles: {},
  //         },
  //       },
  //     ],
  //     basePdf: { width: 210, height: 297, padding: [10, 10, 10, 10] },
  //   };

  //   // Prepare inputs array in the required format
  //   const inputs = data.map(item => ({
  //     mytable: {
  //       Name: item.name,
  //       Phone: item.phone,
  //       Role: item.role,
  //       'Total Calls': `${item.total_call_attempts}/${item.total_call_connected}/${item.total_call_lost}`,
  //     },
  //   }));

  //   try {
  //     // Generate PDF using the defined template and inputs
  //     const pdf = await generate({ template, inputs, plugins: { Table: tableBeta } });

  //     // Handle the PDF output (example: downloading the PDF)
  //     const blob = new Blob([pdf], { type: 'application/pdf' });
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'UserCallReport.pdf');
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(url); // Clean up
  //   } catch (error) {
  //     console.error('Error generating PDF:', error);
  //   }
  // };

  const filterData = (data, columns) => {
    return data.map(row => {
      const filteredRow = {};
      columns.forEach(col => {
        filteredRow[col] = row[col];
      });
      return filteredRow;
    });
  };

  const generatePdf = () => {
    const columnsToInclude = ["name", "phone", "role", "total_call_attempts", "total_call_connected",
      "total_call_not_connected", "total_call_in_progress", "total_call_converted", "total_call_lost", "total_time_taken", "average_time_taken"];
    const data = state.list;

    // Filter the data to include only the specified columns
    const filteredData = filterData(data, columnsToInclude);

    // Create the table header from the columns
    const tableHeaders = columnsToInclude.map(col => {
      switch (col) {
        case "name":
          return "Name";
        case "phone":
          return "Phone";
        case "role":
          return "Role";
        case "total_call_attempts":
          return "Calls_attempts";
        case "total_call_connected":
          return "Connected";
        case "total_call_not_connected":
          return "Not_connected";
        case "total_call_in_progress":
          return "In_progress";
        case "total_call_converted":
          return "Converted";
        case "total_call_lost":
          return "call_lost";
        case "total_time_taken":
          return "Time_taken";
        case "average_time_taken":
          return "avg_time";
        default:
          return col;
      }
    });

    // Create the rows for the table
    const tableRows = filteredData.map(row => {
      return columnsToInclude.map(col => row[col]);
    });

    // Confirm before generating PDF
    const confirmed = window.confirm("Are you sure you want to generate the PDF?");
    if (!confirmed) {
      return; // Cancel PDF generation if not confirmed
    }

    // Generate the PDF using jsPDF
    const doc = new jsPDF();
    doc.text("User Call Report", 10, 10);
    doc.autoTable({
      head: [tableHeaders],
      body: tableRows,
    });

    // Save the PDF
    doc.save("UserCallReport.pdf");
  };

  // Function to generate XLS
  const generateXls = () => {
    const data = state.list;

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "UserCallReport");

    // Convert workbook to a binary XLSX file and trigger download prompt
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    // Prompt the user to confirm download
    const downloadConfirmed = window.confirm(
      "Do you want to download the User Call Report as an XLS file?"
    );

    if (downloadConfirmed) {
      const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "UserCallReport.xlsx";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  };

  // Utility function to convert data to array buffer
  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Report")}
            breadcrumbItem={props.t("User Call Report")}
          />
          <Card className="rounded-4 pb-4">
            <CardBody>

              <div className="float-end">
                <Button className="mx-2" color="success" onClick={generatePdf}>
                  Download PDF Report
                </Button>
                <Button className="mx-2" color="success" onClick={generateXls}>
                  Download XLS Report
                </Button>
              </div>
              <div id="report-table">
                <TableContainer
                  isGlobalFilter
                  columns={columns}
                  data={list}
                  customPageSize={20}
                  filterCom={
                    showFilter ? (
                      <FilterBox
                        state={state}
                        updateState={updateState}
                        handleSubmit={__handleGetUsers}
                      />
                    ) : null
                  }
                  isAddUserList={"Filter"}
                  handleUserClick={() => {
                    updateState({ showFilter: !showFilter });
                  }}
                  classNameHeader="dark"
                  isNextPrvButtonsEnable={true}
                />
              </div>

              {list?.length === 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i className="bx bx-search" style={{ fontSize: "30px" }} />
                  </div>
                  <div className="text-center mt-3">No Report Found</div>
                </div>
              )}

              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    />
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const FilterBox = ({ state, updateState, handleSubmit }) => {
  const { from_date, to_date } = state;
  return (
    <Row className="mb-2 ">
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">From Date</Label>
          <Input
            name="name"
            type="date"
            value={from_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ from_date: e.target.value });
            }}
          />
        </div>
      </Col>
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">To Date</Label>
          <Input
            min={from_date}
            name="name"
            type="date"
            value={to_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ to_date: e.target.value });
            }}
          />
        </div>
      </Col>

      <Col sm="12">
        <div className="float-end mb-4 mt-1">
          <Button
            className="me-2 btn bg-secondary  bg-gradient"
            onClick={() => {
              updateState({
                campaign_type: [],
                from_date: "",
                to_date: "",
              });
            }}
          >
            Clear
          </Button>
          <Button
            onClick={handleSubmit}
            className=" btn bg-success  border-success bg-gradient"
          >
            Search
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default withTranslation()(UserCallReport);





// import React, { useContext, useEffect, useMemo, useState } from "react"
// import {
//   Button,
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Input,
//   Label,
//   Row,
// } from "reactstrap"

// import Breadcrumbs from "../../components/Common/Breadcrumb"

// //i18n
// import { withTranslation } from "react-i18next"
// import { __getApiData, __postApiData } from "constants/apis"
// import { __formatDate, __formatDate2 } from "constants/constantfun"
// import TableContainer from "components/Common/TableContainer"
// // import jsPDF from "jspdf"
// // import html2canvas from "html2canvas"
// import { Template } from '@pdfme/common';
// import { generate } from '@pdfme/generator';
// const UserCallReport = props => {
//   document.title = "User Call Report | Leads"

//   const [state, setState] = useState({
//     list: [],
//     isLoading: false,
//     showFilter: false,
//     campaign_type: [],
//     from_date: __formatDate2(new Date()),
//     to_date: __formatDate2(new Date()),
//   })
//   const { list, isLoading, showFilter, campaign_type, from_date, to_date } =
//     state

//   const columns = useMemo(
//     () => [
//       {
//         Header: <div className="text-center">#</div>,
//         filterable: false,
//         disableFilters: true,
//         accessor: "sr_no",
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: "Name",
//         accessor: "name",
//         filterable: false,
//         disableFilters: true,

//         Cell: cellProps => {
//           return <>{cellProps.value}</>
//         },
//       },
//       {
//         Header: <div className="text-center">Role</div>,
//         accessor: "role",
//         disableFilters: true,
//         filterable: false,
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: <div className="text-center">Phone</div>,
//         accessor: "phone",
//         disableFilters: true,
//         filterable: false,
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: <div className="text-center">Date</div>,
//         accessor: "date",
//         disableFilters: true,
//         filterable: false,
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },

//       {
//         Header: <div className="text-center">Total Calls Attempted</div>,
//         disableFilters: true,
//         accessor: "total_call_attempts",
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: <div className="text-center">Total Calls Connected</div>,
//         disableFilters: true,
//         accessor: "total_call_connected",
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: <div className="text-center">Total Calls Not Connected</div>,
//         disableFilters: true,
//         accessor: "total_call_not_connected",
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: <div className="text-center">Total Calls In Progress</div>,
//         disableFilters: true,
//         accessor: "total_call_in_progress",
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: <div className="text-center">Leads Marked As Converted</div>,
//         disableFilters: true,
//         accessor: "total_call_converted",
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: <div className="text-center">Leads Marked As Lost</div>,
//         disableFilters: true,
//         accessor: "total_call_lost",
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: <div className="text-center">Total Call Time</div>,
//         disableFilters: true,
//         accessor: "total_time_taken",
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//       {
//         Header: <div className="text-center">Average Call Time</div>,
//         disableFilters: true,
//         accessor: "average_time_taken",
//         Cell: cellProps => {
//           return <div className="text-center">{cellProps.value}</div>
//         },
//       },
//     ],
//     []
//   )

//   const updateState = data => setState(state => ({ ...state, ...data }))

//   const __handleGetUsers = () => {
//     updateState({ list: [], isLoading: true })

//     __postApiData(`/api/v1/admin/g_user_call_report`, {
//       from_date: new Date(from_date).valueOf(),
//       to_date: new Date(to_date).valueOf(),
//     })
//       .then(res => {
//         if (res.response.response_code === "200") {
//           return updateState({
//             list: res.data
//               .map((item, i) => ({ ...item, sr_no: i + 1 }))
//               .reverse(),
//             isLoading: false,
//           })
//         }
//         return updateState({ list: [], isLoading: false })
//       })
//       .catch(error => {
//         console.error(error)
//         return updateState({ list: [], isLoading: false })
//       })
//   }

//   useEffect(() => {
//     __handleGetUsers()
//   }, [])

//   // const generatePDF = () => {
//   //   const input = document.getElementById('report-table')
//   //   html2canvas(input).then(canvas => {
//   //     const imgData = canvas.toDataURL('image/png')
//   //     const pdf = new jsPDF()
//   //     const imgProps = pdf.getImageProperties(imgData)
//   //     const pdfWidth = pdf.internal.pageSize.getWidth()
//   //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
//   //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
//   //     pdf.save('report.pdf')
//   //   })
//   // }

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Breadcrumbs
//             title={props.t("Report")}
//             breadcrumbItem={props.t("User Call Report")}
//           />
//           <Card className="rounded-4 pb-4">
//             <CardBody>
//               {/* <Button onClick={generatePDF} color="primary">
//                 Download PDF
//               </Button> */}
//               <Button
//                 color="primary"
//                 onClick={() => generatePdf(list)}
//               >
//                 Download Report
//               </Button>
//               <div id="report-table">
//                 <TableContainer
//                   isGlobalFilter
//                   columns={columns}
//                   data={list}
//                   customPageSize={20}
//                   filterCom={
//                     showFilter ? (
//                       <FilterBox
//                         state={state}
//                         updateState={updateState}
//                         handleSubmit={__handleGetUsers}
//                       />
//                     ) : null
//                   }
//                   isAddUserList={"Filter"}
//                   handleUserClick={() => {
//                     updateState({ showFilter: !showFilter })
//                   }}
//                   classNameHeader="dark"
//                   isNextPrvButtonsEnable={true}
//                 />
//               </div>


//               {list?.length == 0 && !isLoading && (
//                 <div className="p-5">
//                   <div className="text-center ">
//                     <i
//                       className="bx bx-search"
//                       style={{ fontSize: "30px" }}
//                     ></i>
//                   </div>
//                   <div className="text-center mt-3">No Report Found</div>
//                 </div>
//               )}

//               {isLoading && (
//                 <div className="p-5">
//                   <div className="text-center ">
//                     <i
//                       className="bx bx-loader bx-spin"
//                       style={{ fontSize: "30px" }}
//                     ></i>
//                   </div>
//                 </div>
//               )}
//             </CardBody>
//           </Card>
//         </Container>
//       </div>
//     </React.Fragment>
//   )


//   async function generatePdf2(data) {
//     const template = {
//       basePdf: new Uint8Array([]), // You'll need to provide a base PDF
//       schemas: [
//         {
//           header: {
//             type: 'text',
//             position: { x: 0, y: 0 },
//             width: 595,
//             height: 50,
//           },
//           table: {
//             type: 'table',
//             position: { x: 0, y: 50 },
//             width: 595,
//             height: 750,
//           },
//         },
//       ],
//     };

//     const tableHeaders = [
//       '#', 'Name', 'Role', 'Phone', 'Date', 'Total Calls Attempted',
//       'Total Calls Connected', 'Total Calls Not Connected', 'Total Calls In Progress',
//       'Leads Marked As Converted', 'Leads Marked As Lost', 'Total Call Time', 'Average Call Time'
//     ];

//     const tableData = data.map((item, index) => [
//       (index + 1).toString(),
//       item.name,
//       item.role,
//       item.phone,
//       item.date,
//       item.total_call_attempts.toString(),
//       item.total_call_connected.toString(),
//       item.total_call_not_connected.toString(),
//       item.total_call_in_progress.toString(),
//       item.total_call_converted.toString(),
//       item.total_call_lost.toString(),
//       item.total_time_taken,
//       item.average_time_taken,
//     ]);

//     const inputs = [
//       {
//         header: 'User Call Report',
//         table: {
//           headers: tableHeaders,
//           rows: tableData,
//         },
//       },
//     ];

//     try {
//       const pdf = await generate({ template, inputs });
//       const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
//       const link = document.createElement('a');
//       link.href = URL.createObjectURL(blob);
//       link.download = 'UserCallReport.pdf';
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } catch (error) {
//       console.error('Error generating PDF:', error);
//     }
//   }
// }

// export default withTranslation()(UserCallReport)

// const FilterBox = ({ state, updateState, handleSubmit }) => {
//   const { campaign_type, from_date, to_date } = state
//   return (
//     <Row className="mb-2 ">
//       <Col md={"3"} className="">
//         <div className="mb-3">
//           <Label className="form-label">From Date</Label>
//           <Input
//             name="name"
//             type="date"
//             value={from_date}
//             placeholder="Name... "
//             className=" border-0 bg-light rounded-0 p-3"
//             onChange={e => {
//               updateState({ from_date: e.target.value })
//             }}
//           />
//         </div>
//       </Col>
//       <Col md={"3"} className="">
//         <div className="mb-3">
//           <Label className="form-label">To Date</Label>
//           <Input
//             min={from_date}
//             name="name"
//             type="date"
//             value={to_date}
//             placeholder="Name... "
//             className=" border-0 bg-light rounded-0 p-3"
//             onChange={e => {
//               updateState({ to_date: e.target.value })
//             }}
//           />
//         </div>
//       </Col>

//       <Col sm="12">
//         <div className="float-end mb-4 mt-1">
//           <Button
//             className="me-2 btn bg-secondary  bg-gradient"
//             onClick={() => {
//               updateState({
//                 campaign_type: [],
//                 from_date: "",
//                 to_date: "",
//               })
//             }}
//           >
//             Clear
//           </Button>
//           <Button
//             onClick={handleSubmit}
//             className=" btn bg-success  border-success bg-gradient"
//           >
//             Search
//           </Button>
//         </div>
//       </Col>
//     </Row>
//   )
// }



