import React, { useContext } from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import FileOverview from "./FileOverview"
import dataContext from "context/data/dataContext"
import { Link } from "react-router-dom"

const AnaliseBox = ({ }) => {
  return (
    <>
      <Col md="9" className="">
        <Row>
          <LeadAnalysis />
          <CallAnalysis />
        </Row>
        <Row>
          <FileOverview />
        </Row>
      </Col>

      <PathBox />
    </>
  )
}

const PathBox = params => {
  return (
    <Col md="3">
      <Row className="">
        <Col md="12">
          <Link to={"/user-call-report"}>
            <Card
              className="mb-3   rounded-4 p-3 d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-primary font-size-20">
                  <i className="bx bx-phone-call text-white"></i>
                </span>
              </div>
              <h5 className=" mb-0 ms-2  me-auto">User Call Report</h5>
              <i className="bx bx-chevron-right font-size-24"></i>
            </Card>
          </Link>
        </Col>
        <Col md="12">
          <Link to={"/#"}>
            <Card
              className="mb-3   rounded-4 p-3 d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-warning font-size-20">
                  <i className="bx bx-user text-white"></i>
                </span>
              </div>
              <h5 className=" mb-0 ms-2  me-auto">User Login Report</h5>
              <i className="bx bx-chevron-right font-size-24"></i>
            </Card>
          </Link>
        </Col>
        <Col md="12">
          <Link to={"/manage-users"}>
            <Card
              className="mb-3   rounded-4 p-3 d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-info font-size-20">
                  <i className="bx bx-cog bx-spin text-white"></i>
                </span>
              </div>
              <h5 className=" mb-0 ms-2  me-auto">Manage Users</h5>
              <i className="bx bx-chevron-right font-size-24"></i>
            </Card>
          </Link>
        </Col>
        <Col md="12">
          <Link to={"/campaign-report"}>
            <Card
              className="mb-3   rounded-4 p-3 d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-success font-size-20">
                  <i className="fas fa-bullhorn font-size-16 text-white"></i>
                </span>
              </div>
              <h5 className=" mb-0 ms-2  me-auto">Campaign Report</h5>
              <i className="bx bx-chevron-right font-size-24"></i>
            </Card>
          </Link>
        </Col>
        <Col md="12">
          <Link to={"/create"}>
            <Card
              className="mb-3   rounded-4 p-3 d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-primary-subtle font-size-20">
                  <i className="bx bxs-megaphone text-primary"></i>
                </span>
              </div>
              <h5 className=" mb-0 ms-2  me-auto">Create Campaign</h5>
              <i className="bx bx-chevron-right  font-size-24"></i>
            </Card>
          </Link>
        </Col>
        <Col md="12">
          <Link to={"/user-call-report"}>
            <Card
              className="mb-3   rounded-4 p-3 d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-success-subtle font-size-20">
                  <i className="bx bxs-megaphone text-dark"></i>
                </span>
              </div>
              <h5 className=" mb-0 ms-2  me-auto">User Call Report</h5>
              <i className="bx bx-chevron-right  font-size-24"></i>
            </Card>
          </Link>
        </Col>
        <Col md="12">
          <Link to={"/upload-intrested"}>
            <Card
              className="mb-3   rounded-4 p-3 d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-warning-subtle font-size-20">
                  <i className="bx bxs-file text-primary"></i>
                </span>
              </div>
              <h5 className=" mb-0 ms-2  me-auto">Files</h5>
              <i className="bx bx-chevron-right  font-size-24"></i>
            </Card>
          </Link>
        </Col>
      </Row>
    </Col>
  )
}
const CallAnalysis = () => {
  const { callOverView } = useContext(dataContext)
  const { isLoaded, call_attempts, call_connected, ratio } = callOverView
  return (
    <Col md="4">
      <Card className="rounded-4 py-2 ">
        <CardBody>
          <CardTitle>Calls Analysis</CardTitle>
          <Row className="mt-4">
            <Col md="12">
              <Card className="mb-0  shadow-none rounded-4 p-2 d-flex justify-content-between flex-row align-items-center">
                <h6 className="text-secondary mb-0 ">Attempted:</h6>

                {!isLoaded ? (
                  <h4 className=" text-black mb-0">
                    <i className="bx bx-loader bx-spin "></i>
                  </h4>
                ) : (
                  <h4 className=" text-black mb-0">{call_attempts}</h4>
                )}
              </Card>
            </Col>
            <Col md="12">
              <Card className=" mb-0 shadow-none  rounded-4 p-2 d-flex justify-content-between flex-row align-items-center">
                <h6 className="text-secondary mb-0 ">Connected:</h6>
                {!isLoaded ? (
                  <h4 className=" text-black mb-0">
                    <i className="bx bx-loader bx-spin "></i>
                  </h4>
                ) : (
                  <h4 className=" text-black mb-0">{call_connected}</h4>
                )}
              </Card>
            </Col>
            <Col md="12">
              <Card className=" mb-0 shadow-none rounded-4 p-2 d-flex justify-content-between flex-row align-items-center">
                <h6 className="text-secondary mb-0 ">
                  Connected v/s Attempted:
                </h6>
                {!isLoaded ? (
                  <h4 className=" text-black mb-0">
                    <i className="bx bx-loader bx-spin "></i>
                  </h4>
                ) : (
                  <h4 className=" text-black mb-0">{ratio}%</h4>
                )}
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

const LeadAnalysis = () => {
  const { leadOverView } = useContext(dataContext)
  const {
    isLoaded,
    total_open,
    total_in_progress,
    total_closed,
    total_converted,
  } = leadOverView
  return (
    <Col md="8">
      <Card className="rounded-4 py-2">
        <CardBody>
          <CardTitle>Lead Analysis</CardTitle>
          <Row className="mt-4">
            <Col md="3">
              <Card className="  bg-secondary-subtle bg-gradient  rounded-4 p-4 py-3">
                {!isLoaded ? (
                  <h2 className=" text-secondary">
                    <i
                      className="bx bx-loader bx-spin mt-1"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </h2>
                ) : (
                  <h2 className=" text-black">{total_open}</h2>
                )}
                <h6 className="text-secondary ">Open</h6>
              </Card>
            </Col>
            <Col md="3">
              <Card className="  bg-warning-subtle bg-gradient rounded-4 p-4 py-3">
                {!isLoaded ? (
                  <h2 className=" text-warning">
                    <i
                      className="bx bx-loader bx-spin mt-1"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </h2>
                ) : (
                  <h2 className=" text-black">{total_in_progress}</h2>
                )}
                <h6 className="text-warning ">In-Progress</h6>
              </Card>
            </Col>
            <Col md="3">
              <Card className="  bg-info-subtle bg-gradient  rounded-4 p-4 py-3">
                {!isLoaded ? (
                  <h2 className=" text-info">
                    <i
                      className="bx bx-loader bx-spin mt-1"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </h2>
                ) : (
                  <h2 className=" text-black">{total_converted}</h2>
                )}
                <h6 className="text-info ">Converted</h6>
              </Card>
            </Col>
            <Col md="3">
              <Card className="  bg-danger-subtle  bg-gradient rounded-4 p-4 py-3">
                {!isLoaded ? (
                  <h2 className=" text-danger">
                    <i
                      className="bx bx-loader bx-spin mt-1"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </h2>
                ) : (
                  <h2 className=" text-black">{total_closed}</h2>
                )}
                <h6 className="text-danger ">Lost</h6>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

export default AnaliseBox
