import React, { useContext, useEffect, useState } from "react"
import { Container, Row } from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import AnaliseBox from "./comp/AnaliseBox"
import { Data } from "constants/data"

const Dashboard = props => {
  document.title = `Dashboard |Leads`

  const {
    leadOverView,
    setLeadOverView,
    callOverView,
    setCallOverView,
    sourceFileOverView,
    setSourceFileOverView,
  } = useContext(dataContext)

  const __handleGetCallOverViewData = () => {
    __postApiData(`/api/v1/admin/g_calls_overview`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setCallOverView({ ...res.data, isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleGetLeadOverviewData = () => {
    __postApiData(`/api/v1/admin/g_leads_overview`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setLeadOverView({ ...res.data, isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleGetSouceFileOverviewData = () => {
    __postApiData(`/api/v1/admin/g_lead_file_overview`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setSourceFileOverView({ list: res.data, isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    !leadOverView?.isLoaded && __handleGetLeadOverviewData()
    !callOverView?.isLoaded && __handleGetCallOverViewData()
    !sourceFileOverView?.isLoaded && __handleGetSouceFileOverviewData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <AnaliseBox />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
