import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import Notification from 'components/Common/Notification';
import Loader from 'components/Common/Loader';
import { __HOST } from 'constants/apis';

const UploadFile = () => {
  const [state, setState] = useState({
    file_name: '',
    alertMessage: '',
    messageType: '',
    isLoading: false,
    loadingProgress: 10,
  });

  const {
    file_name,
    messageType,
    alertMessage,
    isLoading,
    loadingProgress,
  } = state;

  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    updateState({ file });
  };

  const __handleUpload = () => {
    const formData = new FormData();
    formData.append('file_name', file_name);
    formData.append('file', state.file);

    updateState({ isLoading: true, loadingProgress: 10 });

    axios.post(__HOST() + '/api/v1/admin/upload_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'auth-token': localStorage.getItem('authUser'),
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log(res.data.message);
          updateState({ alertMessage: res.data.message, messageType: 'success' });
          updateState({ isLoading: false, loadingProgress: 100 });
          alert(res.data.message);

          setTimeout(function () {
            window.location.reload();
          }, 3000)
        }
      })
      .catch((error) => {
        console.error(error);
        updateState({ isLoading: false, loadingProgress: 100 });
      });
  };


  return (
    <>
      {alertMessage && (
        <Notification message={alertMessage} type={messageType}
          // closeButton={() => updateState({ alertMessage: '' })}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader title={''} progress={loadingProgress} />}
      {!state.file ? selectFile() : uploadForm()}
    </>
  );

  function uploadForm() {
    return (
      <Row>
        <Col md="12" className="">
          <div className="mb-3">
            <Label className="form-label">File Name</Label>
            <Input
              name="name"
              type="text"
              placeholder="Name... "
              className="border-0 bg-light rounded-0 p-3"
              value={file_name}
              onChange={(e) => {
                updateState({ file_name: e.target.value });
              }}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="float-end">
            <Button onClick={() => __handleUpload()} className="btn bg-primary bg-gradient border-primary mt-3 mb-1">
              Save
            </Button>
          </div>
        </Col>
      </Row>
    );
  }

  function selectFile() {
    return (
      <div className="">
        <div className="input-group mb-3">
          <label
            htmlFor="inputGroupFile01"
            className="p-5 border w-100 text-center"
            style={{ cursor: 'pointer' }}
          >
            <div className="mb-3">
              <i className="display-4 text-muted bx bxs-cloud-upload"></i>
            </div>
            <h5 className="text-secondary">Upload Your File</h5>
            <span></span>
          </label>
          <Input
            id="inputGroupFile01"
            type="file"
            className="form-control form-control d-none"
            onChange={(e) => {
              handleFileUpload(e);
              updateState({ file_name: e.target.files[0].name });
            }}
          />
        </div>
      </div>
    );
  }
};

export default UploadFile;
