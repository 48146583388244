import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { __formatDate, __formatDate2 } from "constants/constantfun"
import TableContainer from "components/Common/TableContainer"
const pathId = {
  1: "Feedback",
  2: "Other",
  3: "Reminder",
  4: "Sales",
  5: "Service",
}
const CampaignReport = props => {
  document.title = "Compaign Report | Leads"

  const { homeOverView, setHomeOverView } = useContext(dataContext)
  const [state, setState] = useState({
    list: [],
    isLoading: false,
    showFilter: false,
    campaign_type: [],
    from_date: __formatDate2(new Date()),
    to_date: __formatDate2(new Date()),
  })
  const { list, isLoading, showFilter, campaign_type, from_date, to_date } =
    state

  const OpenLink = (id, name, from_date, to_date) => {
    window.open(
      `/campaign-user-report/${id}/${name}/${from_date}/${to_date}`,
      "_blank"
    )
  }
  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: "Name",
        accessor: "campaign_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: <div className="text-center">Campaign Type</div>,
        accessor: "campaign_type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{pathId[cellProps.value] || "-"}</div>
          )
        },
      },
      {
        Header: <div className="text-center">Date</div>,
        accessor: "date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: <div className="text-center">Total Calls Attempted</div>,
        disableFilters: true,
        accessor: "total_call_attempts",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Total Calls Connected</div>,
        disableFilters: true,
        accessor: "total_call_connected",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Leads Marked As Converted</div>,
        disableFilters: true,
        accessor: "mark_as_conveted",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Leads Marked As Lost</div>,
        disableFilters: true,
        accessor: "mark_as_lost",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Action</div>,
        disableFilters: true,
        accessor: "_id",
        Cell: cellProps => {
          const data = cellProps.data.find(elem => elem._id === cellProps.value)

          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                <li>
                  <button
                    className="btn btn-sm btn-soft-info"
                    onClick={() =>
                      OpenLink(
                        cellProps.value,
                        data.campaign_name,
                        data.from_date,
                        data.to_date
                      )
                    }
                  >
                    <i className="mdi mdi-eye-outline"></i>
                  </button>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    updateState({ list: [], isLoading: true })

    __postApiData(`/api/v1/admin/g_campaign_report`, {
      campaign_type,
      from_date: new Date(from_date).valueOf(),
      to_date: new Date(to_date).valueOf(),
    })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            list: res.data
              .map((item, i) => ({ ...item, sr_no: i + 1 }))
              .reverse(),
            isLoading: false,
          })
        }
        return updateState({ list: [], isLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ list: [], isLoading: false })
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [])
  useEffect(() => {
    console.log(state)
  }, [state])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Report")}
            breadcrumbItem={props.t("Campaign Report")}
          />
          <Card className="rounded-4 pb-4">
            <CardBody>
              <TableContainer
                isGlobalFilter
                columns={columns}
                data={list}
                customPageSize={20}
                filterCom={
                  showFilter ? (
                    <FilterBox
                      state={state}
                      updateState={updateState}
                      handleSubmit={__handleGetUsers}
                    />
                  ) : null
                }
                isAddUserList={"Filter"}
                handleUserClick={() => {
                  updateState({ showFilter: !showFilter })
                }}
                classNameHeader="dark"
              />

              {list?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No Report Found</div>
                </div>
              )}

              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CampaignReport)

const FilterBox = ({ state, updateState, handleSubmit }) => {
  const { campaign_type, from_date, to_date } = state
  return (
    <Row className="mb-2 ">
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">From Date</Label>
          <Input
            name="name"
            type="date"
            value={from_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ from_date: e.target.value })
            }}
          />
        </div>
      </Col>
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">To Date</Label>
          <Input
            min={from_date}
            name="name"
            type="date"
            value={to_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ to_date: e.target.value })
            }}
          />
        </div>
      </Col>
      <Col md={"6"} className="">
        <div className="mb-3">
          <label className="control-label">Campaign Type</label>
          <Select
            value={campaign_type}
            isMulti={true}
            onChange={e => {
              console.log(e)
              updateState({ campaign_type: e })
            }}
            options={[
              {
                label: "Feedback",
                value: "1",
              },
              {
                label: "Other",
                value: "2",
              },
              {
                label: "Reminder",
                value: "3",
              },
              {
                label: "Sales",
                value: "4",
              },
              {
                label: "Service",
                value: "5",
              },
            ]}
            className="select2-selection border-0 bg-light rounded-0 p-2"
          />
        </div>
      </Col>
      <Col sm="12">
        <div className="float-end mb-4 mt-1">
          <Button
            className="me-2 btn bg-secondary  bg-gradient"
            onClick={() => {
              updateState({
                campaign_type: [],
                from_date: "",
                to_date: "",
              })
            }}
          >
            Clear
          </Button>
          <Button
            onClick={handleSubmit}
            className=" btn bg-success  border-success bg-gradient"
          >
            Search
          </Button>
        </div>
      </Col>
    </Row>
  )
}
